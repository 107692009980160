@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-image: url('./Assets/yral.jpeg');
    background-size: cover;
    background-position: center;
  }




